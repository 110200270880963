import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SideBarView from "../components/SideBarView"
import dateFormat from "dateformat"
import { Link as GatsbyLink } from "gatsby"
import { DataGrid } from "@mui/x-data-grid"
import { styled } from "@mui/system"
import { Typography, Box, Grid } from "@mui/material"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function(dowOffset) {
  dowOffset = typeof (dowOffset) == "number" ? dowOffset : 0 //default dowOffset to zero
  const newYear = new Date(this.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = (day >= 0 ? day : day + 7)
  const daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
  let weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      const nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

const isBrowser = () => typeof window !== "undefined"

const DaysPage = (props) => {
  const year = props?.pageContext?.year
  const currentYear = (new Date()).getFullYear()
  if (isNaN(year) || year < 1900) {
    isBrowser() && (window.location = `/days/${currentYear}`)
    return null
  }
  const startOfYear = new Date(year, 0, 1)
  const endOfYear = new Date(year, 11, 31)
  const endOfYearTimestamp = endOfYear.getTime()
  const datesList = []
  let tempStartDate = new Date(startOfYear)
  tempStartDate.setUTCHours(0, 0, 0, 0)
  tempStartDate.setDate(tempStartDate.getDate() + 1)
  const totalDaysInYear = ((new Date(year, 11, 31)).getTime() - (new Date(year, 0, 1)).getTime()) / 60 / 60 / 24 / 1000 + 1
  const currentDate = new Date()
  currentDate.setUTCHours(0, 0, 0, 0)
  currentDate.setDate(currentDate.getDate() + 1)

  let counter = 1
  while (endOfYearTimestamp >= tempStartDate.getTime()) {
    const date = dateFormat(tempStartDate, "dddd, mmmm dS, yyyy")
    const dayNumber = (tempStartDate.getTime() - currentDate.getTime()) / 60 / 60 / 24 / 1000
    const percentage = Math.round(100 * 100 * counter / totalDaysInYear) / 100
    datesList.push({
      date,
      dayNumber: `Day ${String(counter).padStart(2, "0")}`,
      daysRemaining: `Day ${String(totalDaysInYear - counter).padStart(2, "0")}`,
      daysFromToday: String(dayNumber).padStart(2, "0"),
      percentage: `${percentage} %`,
      timestamp: tempStartDate.getTime()
    })
    tempStartDate.setDate(tempStartDate.getDate() + 1)
    counter++
  }


  const columns = [
    {
      headerName: "Date",
      field: "date",
      key: "date",
      sorter: (a, b) => a.timestamp - b.timestamp,
      fixed: "left",
      flex: 1.75
    },
    {
      headerName: "Day Number",
      field: "dayNumber",
      key: "dayNumber",
      flex: 1
    },
    {
      headerName: "Days Remaining",
      field: "daysRemaining",
      key: "daysRemaining",
      flex: 1
    },
    {
      headerName: "Days from today",
      field: "daysFromToday",
      key: "daysFromToday",
      flex: 1
    },
    {
      headerName: "Day % of year",
      field: "percentage",
      key: "percentage",
      flex: 1
    }
  ]

  return (
    <Container>
      <CustomHeader title={`Day Numbers for ${year}`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <div style={{ marginTop: "20px" }}>
            <p>
              This page lists all days in {year} with day and week numbers.<br/>
              The year {year} has {totalDaysInYear} days. <br/>

              'Percent of year' shows the percentage the year is complete at midnight (start of the day).
              Week number according to ISO-8601.
            </p>
            <Link to={`/days/${year - 1}`}>« day numbers for {year - 1}</Link> | <Link to={`/days/${year - 0 + 1}`}>day
            numbers for {year - 0 + 1} »</Link>
          </div>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            List of days in the year {year} with day number and percentage
          </Typography>
          <div>
            <Box sx={{ height: 600, width: "100%" }}>
              <DataGrid getRowId={row => row.date} rows={datesList} columns={columns} rowKey={"timestamp"} bordered
                        size="small"
                        sticky/>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DaysPage

export const Head = (props) => {
  const year = props?.pageContext?.year
  const siteMetadata = {
    description: "List of days for an year with day number, day number from the end, days offset with current date and the percentage of the year",
    description2: "",
    keywordsContent: "day, number, current date, day percentage, day offset"
  }
  return (
    <Seo
      title={`Epoch Converter - Day number for ${year}`}
      canonicalUrl={`https://www.epochconverter.io/days/${year}`}
      siteMetadata={siteMetadata}
    />
  )
}
